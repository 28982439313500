import React, { useState, useEffect } from 'react'
import { RingLoader } from 'react-spinners'
import Modal from 'react-modal'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import './App.css'

function App() {
  const [loading, setLoading] = useState(true)
  const [imagesLoaded, setImagesLoaded] = useState(0)

  const [background] = useState('/background.png')
  const [tabi] = useState('/tabi.png')
  const [upper] = useState('/upper.png')
  const [blueBallon] = useState('/blue-ballon.png')
  const [brownBallon] = useState('/brown-ballon.png')
  const [presents] = useState('/presents.png')
  const [deco] = useState('/deco.png')
  const [door] = useState('/open-door.png')
  const [garimcheonOff] = useState('/garimcheon-off.png')
  const [hina] = useState('/hina.png')
  const [riko] = useState('/riko.png')
  const [decoRemove] = useState('/deco-remove.png')
  const [garimcheon] = useState('/garimcheon.png')
  const [haedongDefault] = useState('/haedong-default.png')
  const [haedongFake] = useState('/haedong.png')
  const [train] = useState('/train.png')
  const [trainNoCake] = useState('/train-nocake.png')
  const [yunirize] = useState('/yunirize.png')
  const [minecraftPreview1] = useState('/minecraft-preview1.png')
  const [minecraftPreview2] = useState('/minecraft-preview2.png')

  const [decoVisiable, setDecoVisiable] = useState(true)
  const [upperVisible, setUpperVisible] = useState(true)
  const [doorVisiable, setDoorVisiable] = useState(false)
  const [mincraftModalVisiable, setMinecraftModal] = useState(false)
  const [fanartModalVisiable, setFanartModalVisiable] = useState(-1)
  const [garimcheonVisiable, setGarimcheonVisiable] = useState(true)
  const [hinaVisiable, setHinaVisiable] = useState(false)
  const [rikoVisiable, setRikoVisiable] = useState(false)
  const [haedongVisiable, setHaedongVisiable] = useState(false)
  const [imgViewerIdx, setImgViewerIdx] = useState(0)

  const [ballonPosition, setBallonPosition] = useState({})
  const [ballonBoxPosition, setBallonBoxPosition] = useState({})

  const [ballonDragPosition, setBallonDragPosition] = useState({
    'x': 0,
    'y': 0
  })

  const [ballonDragPositionOffset, setBallonDragPositionOffset] = useState({
    'x': 0,
    'y': 0
  })

  const [trainPosition, setTrainPosition] = useState({})

  const [trainDragPosition, setTrainDragPosition] = useState({
    'x': 0
  })

  const [trainDragPositionOffset, setTrainDragPositionOffset] = useState({
    'x': 0  
  })



  const [isDraggingBallon, setIsDraggingBallon] = useState(false)
  const [isDraggingTrain, setIsDraggingTrain] = useState(false)

  const handleMouseDown = e => {
    if (e.target.id === 'ballonBox' && isDraggingBallon === false && isDraggingTrain === false) {
      setIsDraggingBallon(true)
      setBallonDragPosition({
        x: e.clientX - ballonDragPositionOffset.x,
        y: e.clientY - ballonDragPositionOffset.y
      })
    } else if (e.target.id === 'trainBox' && isDraggingBallon === false && isDraggingTrain === false) {
      setIsDraggingTrain(true)
      setTrainDragPosition({
        x: e.clientX - trainDragPositionOffset.x
      })
    }
  };

  const handleMouseMove = e => {
    e.preventDefault()

    if (isDraggingBallon) {
      const movedX = e.clientX - ballonDragPosition.x 
      const movedY = e.clientY - ballonDragPosition.y

      setBallonPosition({
        'transform': 'translate3d(' + movedX + 'px, ' + movedY + 'px, 0)' 
      })

      setBallonBoxPosition({
        'transform': 'translate3d(' + movedX + 'px, ' + movedY + 'px, 0)' 
      })

      setBallonDragPositionOffset({
        x: movedX,
        y: movedY
      })
    } else if (isDraggingTrain) {
      const movedX = e.clientX - trainDragPosition.x 

      setTrainPosition({
        'transform': 'translate3d(' + movedX + 'px, 0px, 0)' 
      })

      setTrainDragPositionOffset({
        x: movedX
      })
    }
  }

  const handleMouseUp = () => {
    // if (isDraggingBallon) {
      setIsDraggingBallon(false)
      setBallonDragPosition({
        ...setBallonDragPositionOffset
      })
    // } else if (isDraggingTrain) {
      setIsDraggingTrain(false)
      setTrainDragPosition({
        ...setTrainDragPositionOffset
      })
    // }
  }

  const handleHaedong = () => {
    if (haedongVisiable === false && hinaVisiable === false) {
      setHaedongVisiable(true)
    } else if (haedongVisiable === true && hinaVisiable === false) {
      setHaedongVisiable(false)
      setHinaVisiable(true)
    } else if (haedongVisiable === false && hinaVisiable === true) {
      setHaedongVisiable(false)
      setHinaVisiable(false)
    }
  }

  const handleCakeClick = () => {
    setRikoVisiable(true)
  }


  const totalImages = 18;

  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };

  useEffect(() => {
    if (imagesLoaded === totalImages) {
      setLoading(false);
    }
  }, [imagesLoaded, totalImages]);

  const downloadFile = () => {
    fetch('/minecraft.zip').then(res => res.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'minecraft.zip'); 
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
  }

  const handleFanart = (e, idx) => {
    console.log(`Fanart: ${idx+1}`)
    setFanartModalVisiable(idx)
  }

  const imageOpen = path => {
    window.open(`${window.origin}${path}`, path, 'popup')
  }

  const defaultStyle = {
    'display': 'flex',
    'zIndex': '99',
    'position': 'absolute',
    'width': '3vw',
    'height': '3vw',
    'left': '33.32vw',
    'top': '84vh',
    'backgroundColor': 'rgb(0, 0, 0)',
    'opacity': '0',
    'cursor': 'pointer',
    ...trainPosition
  }

  const fanartInfo = [
    {
      style: {
        ...defaultStyle,
        'left': '7.7vw',
      },
      name: '솔미',
      path: '/fanart/1-솔미.jpg',
      description: '입덕한지 얼마 안된 뿡댕이지만 방송해주셔서 감사하고, 생일 축하드립니다.'
    },
    {
      style: {
        ...defaultStyle,
        'left': '2.7vw',
        'height': '4vw',
        'top': '81vh',
      },
      name: '백향금',
      path: '/fanart/2-백향금.png',
      description: `타비야, 생일 축하해! 항상 즐거운 방송으로 지겹고 괴로웠던 내 인생에 활력을 주는 너에게 정말 고마워. 
네 덕분에 힘든 날도 웃으면서 보낼 수 있었어. 오늘은 너에게도 그런 따뜻함과 기쁨이 가득한 날이 되길 바래! 
앞으로도 우리 항상 함께 모험하자! 난 뿡댕이니까, 언제나 네 편에서 널 응원할게. 우리 오래오래 즐겁게 지내자!
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-5.2vw',
        'width': '5vw'
      },
      name: '촹장창',
      path: '/fanart/3-촹장창.png',
      description: `안녕, 타비야! 데뷔 이후 두번째 생일 너무너무 축하해!
최근 들어 갓생을 살기 시작한 타비의 밝은 모습과
발전한 노래 실력에 뿌대이는 너무 감동이야...! 리스펙!
앞으로도 쭉 같이 행복한 나날들만 가득해지자~
다시 한번 생일 진심으로 축하해!
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-12.2vw',
        'height': '4vw',
        'width': '5vw',
        'top': '82vh'
      },
      name: '부끅곰',
      path: '/fanart/4-부끅곰.png',
      description: `안녕하세요! 저는 수상하게 동탄룩을 많이 그리는 일러레 부끅곰이라고 합니다.

일단! 스텔라이브 유니버스 정타비의 생일을 진심으로 축하합니다~~!!짝짝짝짝!!!╰(*°▽°*)╯

이렇게 타비님의 생일에 팬아트로 참여 할 수 있게 되어 너무 너무 기쁩니다!
(사실 중간에 코로나 이슈로 조금 퀄리티가 떨어진거 같기도...너무 서우내.;ㅁ;)

저는 이번에 유입된지 얼마 안된 뿡댕이에요! 타비님의 '따비 서우내;ㅁ;' 영상을 보고 뿡댕이가 되었답니다.
(너무 귀엽자너..너무 너무 귀엽자너!! 몰래 뒷사과도 하고 진짜..;;왜케 귀여운건데!!)
영상을 볼 때 마다 이 사람은 정말 사람 자체가 선한 사람이구나 라는걸 알 수 있었고 그래서 점점 더 좋아하게 된거 같아요.
우울해질 때나 기분이 안 좋아질 때도 타비님 방송이나 유튜브를 보면 항상 웃음이 지어져요. 이게 타비님의 가장 큰 장점이 아닐까 싶습니다.
그래서 저도 타비님이 기분이 좋아지고 웃을 수 있는 일러스트를 그리는게 목표에요! 지켜봐주십셔! (❁´◡\`❁) 

우리 타비님이 항상 웃으면서 밝은 방송을 할 수 있도록 팬아트 많이 많이 그리면서 항상 응원하겠습니다!! 
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-18.3vw'
      },
      name: '석자',
      path: '/fanart/5-석자.png',
      description: `간간히 편집영상들을 보던 어느날 너가 유튜브에서 라이브로 노래부르러 왔었던게 기억나. 

그대로 방송까지 따라들어갔었는데, 벌써 시간이 이렇게 흘러버렸어. 

처음엔 그저 웃기고 재미있어서 방송을 봤었는데, 힘든일들을 지나보내며 
이겨내고 성장해서 울고 다시 웃어주는 너를 보며 많은 용기와 위로를 받았어.

타비 덕분에 하루를 더 즐겁고 열심히 지낼 수 있게 된 것 같아.

방송 열심히 해줘서 고맙고, 앞으로도 오래오래 봤으면 좋겠다. 

생일 축하해 타비야.
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-24.7vw',
        'top': '83.2vh',
        'width': '4vw',
        'height': '4vw'
      },
      name: '진도갓',
      path: '/fanart/6-진도갓.png',
      description: `타비님 데뷔 이후 두번째 생일 축하합니다.
뿡댕이 로 지내며 타비님 을 기다리는 시간이 익숙해지고 
타비님의 야랄도 함박웃음 지으며 표독해진 모습마저 귀엽게 볼수 있게 된것 같습니다.
정말 자부심이 생기고 타비님을 100%즐길수 있게 되어 뿌듯함을 느끼며 하루하루 를 살아가고 있습니다.
타비님 항상 뿡댕이 들과 친구처럼 티키타카 해주시고 재미있는 방송 해주셔서 감사합니다.
저도 뿡댕이로서 타비님을 가슴속에 새기고 몸과 마음을 단련하여 타비님의 방송을 계속해서 즐길수 있도록 노력하겠습니다.
타비님 건강하시고 매일 행복하시길....
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-33.3vw',
        'top': '83.6vh',
        'width': '3.5vw',
        'height': '3.5vw'
      },
      name: 'PGV',
      path: '/fanart/7-PGV.jpg',
      description: `타비야 생일 정말 축하해!

타비를 알고나서 내 일상도 이전보다 밝아진 것 같아
항상 우릴 위해 즐겁게 방송해줘서 고맙고 
오늘 진짜 행복한 생일 보냈음 좋겠어
오늘만이 아니라 앞으로도 쭉 행복하길 바라고
방송 오래오래 아프지말고 함께 하자

너를 가장 좋아하는 뿡댕이가
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-40.5vw',
        'top': '78vh',
        'width': '5vw',
        'height': '6.5vw'
      },
      name: '경경채',
      path: '/fanart/8-경경채.jpg',
      description: `타비님! 생일 진심으로 축하드립니다.
생방은 자주 보진 못하지만 항상 유튜브랑 다시 보기 흐뭇하게 돌려본답니다.
언제나 의욕 넘치고 배려심 가득한 귀여운 타비님.
항상 건강하셨음 좋겠고, 슬픈 날 보단 웃음 가득한 날들로 가득한 한 해가 되셨음 좋겠습니다.
멀리서지만 항상 응원합니다 사랑해요~ 다시 한번 생일 축하드려요
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-44.3vw',
        'top': '85.3vh',
        'width': '2vw',
        'height': '2vw'
      },
      name: '낙엽마른풀',
      path: '/fanart/9-낙엽마른풀.mp4',
      description: ``
    },
    {
      style: {
        ...defaultStyle,
        'left': '-50.5vw',
        'top': '87vh',
        'width': '2vw',
        'height': '1.5vw'
      },
      name: '므밍',
      path: '/fanart/10-므밍.gif',
      description: `반짝반짝 빛나는 타비야 생일 축하해! `
    },
    {
      style: {
        ...defaultStyle,
        'left': '-59.6vw',
        'top': '83vh',
        'width': '3vw',
        'height': '4.5vw'
      },
      name: '요오오오',
      path: '/fanart/11-요오오오.png',
      description: `타비님 생일 축하해요! 앞으로도 쭉 함께해요!!!!!`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-67vw',
        'top': '83vh',
        'width': '6vw',
        'height': '4.5vw'
      },
      name: '설탕파우더',
      path: '/fanart/12-설탕파우더.png',
      description: `타비 두번째 생일 너무 축하해!
매일 별처럼 밝게 또 은은하게 비춰줘서 고마워
타비가 비춰준 그 빛을 이정표 삼아 나아갈 수 있어서 더 고마워
뿡댕이가 타비를 보며 매일을 생일같은 하루를 보내는만큼
타비도 생일 행복한 하루이기를 바랄게 !
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-75vw',
        'top': '83vh',
        'width': '6vw',
        'height': '4.5vw'
      },
      name: '오키드',
      path: '/fanart/13-오키드.png',
      description: `생일 축하해 타비야! 작년도 올해도 열심히 달려와줘서 고맙고 앞으로도 뿡댕이들이 곁에 있어줄께`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-79vw',
        'top': '84vh',
        'width': '3vw',
        'height': '3vw'
      },
      name: '파랑무르',
      path: '/fanart/14-파랑무르.png',
      description: `타비 님  데뷔 후 두 번째 생일 축하드려요! 
앞으로도 뿡댕이 들이랑 재미있고 행복한 방송 오래오래 해주세요!
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-89vw',
        'top': '84vh',
        'width': '5vw',
        'height': '3vw'
      },
      name: '낙원',
      path: '/fanart/15-낙원.png',
      description: `타비야 생일 너무 축하해!!! 모두에게 사랑받고 행복한 타비가 되면 좋겠어 항상 응원하고 사랑해 뿡댕이가.`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-95vw',
        'top': '82vh',
        'width': '5vw',
        'height': '4vw'
      },
      name: 'TUS',
      path: '/fanart/16-TUS.jpg',
      description: `앞니가 매력적인 타비님 두 번째 생일 너무 축하드려요!!`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-101vw',
        'top': '83vh',
        'width': '3vw',
        'height': '3.5vw'
      },
      name: '무떡',
      path: '/fanart/17-무떡.jpg',
      description: `따비따비의 2번째 생일을 축하합니다!!`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-106vw',
        'top': '83vh',
        'width': '4vw',
        'height': '3.5vw'
      },
      name: '종종',
      path: '/fanart/18-종종.png',
      description: `따비야 생일 잘 보내고 항상 응원하고 있다
너랑 우리는 끝까지 가는거야~
`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-117.3vw',
        'top': '83vh',
        'width': '5.2vw',
        'height': '3.5vw'
      },
      name: '연어제티',
      path: '/fanart/19-연어제티.png',
      description: `넌 누룽지나 먹어라 정타비. 딴 거 먹지 마 걍. 생일은 축하해 ㅎㅁㅎ`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-122vw',
        'top': '80.6vh',
        'width': '3vw',
        'height': '5vw'
      },
      name: '게흥',
      path: '/fanart/20-게흥.png',
      description: `뿡댕이의 영원한 히어로, 그리고 뿡댕이의 주인공 타비! 두번째 생일 너무너무 축하해
언제나 뿡댕이들을 위해 노력해줘서 고맙고, 그런 타비를 언제까지고 곁에서 응원할게
지나온 시간들을 돌아봤을 때 우리는 참 즐거웠고, 앞으로는 더 행복할거야
뿡댕이의 소중한 단 하나의 별 타비야 생일 축하해, 그리고 언제나 좋아해`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-129vw',
        'top': '80.6vh',
        'width': '3.9vw',
        'height': '5vw'
      },
      name: '강한여성정타비',
      path: [
        '/fanart/21-강한여성정타비/1.png',
        '/fanart/21-강한여성정타비/2.png',
        '/fanart/21-강한여성정타비/3.png',
        '/fanart/21-강한여성정타비/4.png',
        '/fanart/21-강한여성정타비/5.png',
        '/fanart/21-강한여성정타비/6.png',
        '/fanart/21-강한여성정타비/7.png',
        '/fanart/21-강한여성정타비/8.png',
        '/fanart/21-강한여성정타비/9.png',
        '/fanart/21-강한여성정타비/10.png',
        '/fanart/21-강한여성정타비/11.png',
        '/fanart/21-강한여성정타비/12.png',
        '/fanart/21-강한여성정타비/13.png',
        '/fanart/21-강한여성정타비/14.png',
        '/fanart/21-강한여성정타비/15.png',
        '/fanart/21-강한여성정타비/16.gif',
      ],
      description: `따비야 니 야랄이 제일아파 사랑해`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-135.5vw',
        'top': '80.6vh',
        'width': '4.3vw',
        'height': '5vw'
      },
      name: '김땅숙',
      path: '/fanart/22-땅숙이.png',
      description: `크하하!! 순순히 방송을 천년만년 해주지 않으면 두 손 꼭꼭 모아 빌어주겠다.`
    },
    {
      style: {
        ...defaultStyle,
        'left': '-142.5vw',
        'top': '82vh',
        'width': '4.3vw',
        'height': '4vw'
      },
      name: '뜨마',
      path: '/fanart/23-뜨마.jpg',
      description: ``
    },
    {
      style: {
        ...defaultStyle,
        'left': '-149.2vw',
        'top': '82vh',
        'width': '4.3vw',
        'height': '4vw'
      },
      name: '야나낭',
      path: '/fanart/24-야나낭.jpg',
      description: ``
    },
    {
      style: {
        ...defaultStyle,
        'left': '-157.5vw',
        'top': '84.5vh',
        'width': '2.2vw',
        'height': '2vw'
      },
      name: '토라미',
      path: '/fanart/25-토라미.mp4',
      description: ``
    }
  ]

  const styles = {
    'tabi': {
      'position': 'absolute',
      'width': '55%',
      'left': '23vw',
      'top': '-1.7vh',
      'display': 'flex',
      'zIndex': '2'
    },
    'yunirize': {
      'position': 'absolute',
      'width': '11.1%',
      'left': '67.7vw',
      'top': '56.8vh',
      'display': 'flex',
      'zIndex': '2'
    },
    'upper': {
      'position': 'absolute',
      'width': '55%',
      'left': '23vw',
      'top': '-1.7vh',
      'display': 'flex',
      'zIndex': '2',
      'visibility': upperVisible ? 'visible' : 'hidden'
    },
    'blueBallon': {
      'position': 'absolute',
      'display': 'flex',
      'width': '40%',
      'left': '37.7vw',
      'top': '17.1vh',
      'zIndex': '3',
      ...ballonPosition
    },
    'brownBallon': {
      'position': 'absolute',
      'width': '55%',
      'left': '23.5vw',
      'top': '-0.56vh',
      'display': 'flex',
      'zIndex': '1'
    },
    'presents': {
      'position': 'absolute',
      'width': '55%',
      'left': '23.5vw',
      'top': '-0.56vh',
      'display': 'flex',
      'zIndex': '1'
    },
    'deco': {
      'position': 'absolute',
      'width': '55%',
      'left': '22.1vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '1'
    },
    'decoRemove': {
      'position': 'absolute',
      'width': '55%',
      'left': '22.1vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': decoVisiable ? 'visible' : 'hidden'
    },
    'door': {
      'position': 'absolute',
      'width': '55%',
      'left': '22.1vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '0',
      'visibility': doorVisiable ? 'visible' : 'hidden'
    },
    'garimcheon': {
      'position': 'absolute',
      'width': '55%',
      'left': '22.3vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': garimcheonVisiable ? 'visible' : 'hidden'
    },
    'garimcheonOff': {
      'position': 'absolute',
      'width': '55%',
      'left': '22.3vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': !garimcheonVisiable ? 'visible' : 'hidden'
    },
    'haedongDefault': {
      'position': 'absolute',
      'width': '55%',
      'left': '24vw',
      'top': '-1.1vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': !haedongVisiable ? 'visible' : 'hidden'
    },
    'haedongFake': {
      'position': 'absolute',
      'width': '55%',
      'left': '24vw',
      'top': '-1.1vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': haedongVisiable ? 'visible' : 'hidden'
    },
    'train': {
      'position': 'absolute',
      'width': '250%',
      'left': '-190vw',
      'top': '63.3vh',
      'display': 'flex',
      'zIndex': '3',
      'visibility': !rikoVisiable ? 'visible' : 'hidden',
      ...trainPosition
    },
    'trainNoCake': {
      'position': 'absolute',
      'width': '250%',
      'left': '-190vw',
      'top': '63.3vh',
      'display': 'flex',
      'zIndex': '3',
      'visibility': rikoVisiable ? 'visible' : 'hidden',
      ...trainPosition
    },
    'hina': {
      'position': 'absolute',
      'width': '55%',
      'left': '24vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': hinaVisiable ? 'visible': 'hidden'
    },
    'riko': {
      'position': 'absolute',
      'width': '55%',
      'left': '24vw',
      'top': '-0.85vh',
      'display': 'flex',
      'zIndex': '1',
      'visibility': rikoVisiable ? 'visible': 'hidden'
    },
    'containerLeft': {
      'backgroundColor': 'rgb(253, 188, 15)',
      'position': 'absolute',
      'width': '40vw',
      'height': '100vh',
      'left': '-17vw',
      'top': '0vh',
      'display': 'flex',
      'zIndex': '99'
    },
    'containerRight': {
      'backgroundColor': 'rgb(253, 188, 15)',
      'position': 'absolute',
      'width': '40vw',
      'height': '100vh',
      'left': '79vw',
      'top': '0vh',
      'display': 'flex',
      'zIndex': '99'
    },
    

    // clickBoxes 
    'clickBox': {
      'deco': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': '1vw',
        'height': '1vw',
        'left': '75.5vw',
        'top': '37.7vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer'
      },
      'train': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': '250vw',
        'height': '4.5vw',
        'left': '-190.8vw',
        'top': '90vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer',
        ...trainPosition
      },
      'trainCake': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': '4.7vw',
        'height': '4vw',
        'left': '33.32vw',
        'top': '81.3vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer',
        ...trainPosition
      },
      'upper': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': '16.5vw',
        'height': '11.5vw',
        'left': '23.2vw',
        'top': '15.7vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer'
      },
      'haedong': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': '4.5vw',
        'height': '5.4vw',
        'left': '74.3vw',
        'top': '88.6vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer'
      },
      'doorOpen': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': !doorVisiable ? '2.47vw' : '0.7vw',
        'height': !doorVisiable ? '1.35vw' : '1vw',
        'left': !doorVisiable ? '52.5vw' : '64.1vw',
        'top': !doorVisiable ? '61.2vh' : '61.7vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer'
      },
      'garimcheon': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': garimcheonVisiable ? '11vw' : '20vw',
        'height': garimcheonVisiable ? '2.5vw' : '2.5vw',
        'left': garimcheonVisiable ? '40vw' : '30vw',
        'top': garimcheonVisiable ? '37vh' : '95vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer'
      },
      'ballon': {
        'display': 'flex',
        'position': 'absolute',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer',
        'width': '9vw' ,
        'height': '9vw' ,
        'left': '67.7vw',
        'top': '55vh',
        'zIndex': '100',
        ...ballonBoxPosition
      },
      'mineCraft': {
        'display': 'flex',
        'zIndex': '99',
        'position': 'absolute',
        'width': '3vw',
        'height': '3vw',
        'left': '74vw',
        'top': '80vh',
        'backgroundColor': 'rgb(0, 0, 0)',
        'opacity': '0',
        'cursor': 'pointer'
      }
    }
  }

  const override = {
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'top': '0',
    'left': '0',
    'display': 'flex',
    'textAlign': 'center',
    'justifyContent': 'center',
    'alignItems': 'center',
    'zIndex': '9999',
    'backgroundColor': 'white',
    'transition': "visibility 2s 1s ease-in-out, opacity 1s 1s ease-in-out",
    'opacity': loading ? '1' : '0',
    'visibility': loading ? 'visible' : 'hidden',
  }

  const hinaOverlay = {
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'top': '0',
    'left': '0',
    'display': 'flex',
    'textAlign': 'center',
    'justifyContent': 'center',
    'alignItems': 'center',
    'zIndex': !hinaVisiable ? '-888889' : '99999',
    'transition': "background-color 0.3s ease-in, visibility 1s ease-in, opacity 0.7s ease-in",
    'opacity': !hinaVisiable ? '1' : '0',
    'visibility': !hinaVisiable ? 'visible' : 'hidden',
    'backgroundColor': !hinaVisiable ? '' : 'rgb(200, 200, 200)'
  }

  const rikoOverlay = {
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'top': '0',
    'left': '0',
    'display': 'flex',
    'textAlign': 'center',
    'justifyContent': 'center',
    'alignItems': 'center',
    'zIndex': !rikoVisiable ? '-888889' : '99999',
    'transition': "visibility 2s ease-in, opacity 2s ease-in",
    'opacity': !rikoVisiable ? '1' : '0',
    'visibility': !rikoVisiable ? 'visible' : 'hidden',
    'backgroundColor': !rikoVisiable ? '' : 'rgb(200, 200, 200)'
  }

  const modalStyle = {
    overlay: {
      'display': 'flex',
      'textAlign': 'center',
      'justifyContent': 'center',
      'alignItems': 'center',
      'zIndex': '99999',
      'width': "100%",
      'height': "100vh",
      'position': 'fixed',
      'top': '0',
      'left': '0'
    },
    content: {
      width: "25vw",
      height: "40vh",
      zIndex: "150",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
      backgroundColor: "white",
      justifyContent: "center",
      overflow: "auto",
    }
  }

  const fanartModalStyle = {
    overlay: {
      'display': 'flex',
      'textAlign': 'center',
      'justifyContent': 'center',
      'alignItems': 'center',
      'zIndex': '99999',
      'width': "100%",
      'height': "100vh",
      'position': 'fixed',
      'top': '0',
      'left': '0'
    },
    content: {
      width: "40vw",
      height: "80vh",
      zIndex: "150",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
      backgroundColor: "white",
      justifyContent: "center",
      overflow: "auto",
    }
  }

  return (
    <div className="App">
        <div style={hinaOverlay}></div>
        <div style={rikoOverlay}></div>

        <div style={override}>
          <RingLoader 
            color = "#87ddfe"
            size = "100px"
          />
        </div>
            
        <div className="background-container" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}  onMouseMove={handleMouseMove}>
          <div style={styles.containerLeft}></div>
          <div style={styles.containerRight}></div>
          
          <img className="background" src={background} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          <img src={yunirize} style={styles.yunirize} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <img src={tabi} style={styles.tabi} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <div style={styles.clickBox.upper} onClick={() => setUpperVisible(prev => !prev)} className="clickBox"></div>
          <img src={upper} style={styles.upper} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <img src={blueBallon} style={styles.blueBallon} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          
          <div style={styles.clickBox.ballon} className="clickBox" id="ballonBox"></div>
          <img src={brownBallon} style={styles.brownBallon} onLoad={handleImageLoad}  alt="새로고침해주세요"></img>

          <img src={presents} style={styles.presents} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <img src={deco} style={styles.deco} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <div style={styles.clickBox.deco} onClick={() => setDecoVisiable(prev => !prev)} className="clickBox"></div>
          <img src={decoRemove} style={styles.decoRemove} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <div style={styles.clickBox.garimcheon} onClick={() => setGarimcheonVisiable(prev => !prev)} className="clickBox"></div>
          <img src={garimcheon} style={styles.garimcheon} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          <img src={garimcheonOff} style={styles.garimcheonOff} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <div style={styles.clickBox.haedong} onClick={handleHaedong} className="clickBox"></div>
          <img src={haedongDefault} style={styles.haedongDefault} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          <img src={haedongFake} style={styles.haedongFake} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          <img src={hina} style={styles.hina} onLoad={handleImageLoad} alt="새로고침해주세요"></img>

          <div style={styles.clickBox.train} className="clickBox" id="trainBox"></div>
          <div style={styles.clickBox.trainCake} onClick={handleCakeClick} className="clickBox"></div>
          {fanartInfo.map((e, i) => 
            <div id={`fanart-${i}`} style={e.style} onClick={e => handleFanart(e, i)} className="clickBox"></div>
          )}
          
          <img src={train} style={styles.train} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          <img src={trainNoCake} style={styles.trainNoCake} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          <img src={riko} style={styles.riko} onLoad={handleImageLoad} alt="새로고침해주세요"></img>


          <div style={styles.clickBox.doorOpen} onClick={() => setDoorVisiable(prev => !prev)} className="clickBox"></div>
          <img src={door} style={styles.door} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
          { /*
          <div style={styles.clickBox.mineCraft} onClick={() => setMinecraftModal(prev => !prev)} className="clickBox"></div>
          <Modal
            isOpen={mincraftModalVisiable}
            onRequestClose={() => setMinecraftModal(false)}
            ariaHideApp={false}
            style={modalStyle}
            contentLabel="Pop up Message"
            shouldCloseOnOverlayClick={true}
          >
            <h2>마인크래프트 스킨</h2>
            <Stack spacing={4} direction="row" sx={{ justifyContent: "center", alignItems: "center" }}>
              <img src={minecraftPreview1} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
              <img src={minecraftPreview2} onLoad={handleImageLoad} alt="새로고침해주세요"></img>
            </Stack>
            <br/><br/>
            <Button variant="contained" onClick={downloadFile}>다운로드</Button>
          </Modal>
          */}

          <Modal
            isOpen={fanartModalVisiable >= 0}
            onRequestClose={() => setFanartModalVisiable(-1)}
            ariaHideApp={false}
            style={fanartModalStyle}
            contentLabel="Pop up Message"
            shouldCloseOnOverlayClick={true}
          >
            <div>
              <h2>{fanartInfo[fanartModalVisiable]?.name}님 팬아트</h2>
              {/* <br/><br/> */}
              {  
                fanartInfo[fanartModalVisiable]?.description !== '' ? <div
                  style={{
                    width: "80%",
                    height: "auto",
                    whiteSpace: "pre-wrap",
                    border: "1px solid #ccc",
                    padding: "10px",
                    overflowY: "auto",
                    // left: '10%',
                    // display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                    'marginLeft': '10%'
                  }}
                >
                  {fanartInfo[fanartModalVisiable]?.description ?? fanartInfo[fanartModalVisiable]?.description} 
                </div>
                : ''
              }
              <br/><br/>
                {
                fanartModalVisiable === 20 
                  ? <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      justifyContent: 'center',
                    }}>
                      <ArrowBackIosIcon onClick={e => imgViewerIdx >= 1 ? setImgViewerIdx(imgViewerIdx - 1) : ''} color="primary" sx={{ fontSize: 40, pointer: 'cursor' }}/>
                        <img src={fanartInfo[fanartModalVisiable]?.path[imgViewerIdx]} onClick={(e) => imageOpen(fanartInfo[fanartModalVisiable]?.path.path[imgViewerIdx])} alt="새로고침해주세요" style={{'width': '60%', 'height': 'auto', 'cursor': 'pointer'}}></img>
                      <ArrowForwardIosIcon onClick={e => imgViewerIdx <= 14 ? setImgViewerIdx(imgViewerIdx + 1) : ''} color="primary" sx={{ fontSize: 40, pointer: 'cursor' }}/>
                    </div> 
                  : fanartInfo[fanartModalVisiable]?.path.split('.').pop() === 'mp4' 
                      ?  <video controls src={fanartInfo[fanartModalVisiable]?.path} alt="새로고침해주세요" style={{'width': '60%', 'height': 'auto', 'cursor': 'pointer'}}></video>
                      : <img src={fanartInfo[fanartModalVisiable]?.path} onClick={(e) => imageOpen(fanartInfo[fanartModalVisiable]?.path)} alt="새로고침해주세요" style={{'width': '60%', 'height': 'auto', 'cursor': 'pointer'}}></img>
                }
                
              </div>
          </Modal>

        </div>
    </div>
  );
}

export default App;
